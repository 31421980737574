import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileName"];

  populateFileField(event) {
    const files = event.target.files;
    const selectedFileNames = Array.from(files)
      .map((file) => `${file.name}<br>`)
      .join("");
    this.fileNameTarget.innerHTML = selectedFileNames;
  }
}
