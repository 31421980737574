import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-visibility"
export default class extends Controller {
  static targets = ["input", "block"]

  connect() {
    this.toggle()
  }

  toggle() {
    const value = this.inputTarget.value

    for(const target of this.blockTargets) {
      if (target.dataset.blockSelector === value) {
        target.classList.remove("d-none")
      } else {
        target.classList.add("d-none")
      }
    }
  }
}
