export const headerScrollHandler = () => {
  const mainHeader = document.querySelector(".main-header");
  if (mainHeader) {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const scrollLink = document.querySelector(".scroll-to-top");

    if (scrollPosition > 1) {
      mainHeader.classList.add("fixed-header", "animated", "slideInDown");
      if (scrollLink) {
        scrollLink.style.opacity = "1";
        scrollLink.style.visibility = "visible";
      }
    } else {
      mainHeader.classList.remove("fixed-header", "animated", "slideInDown");
      if (scrollLink) {
        scrollLink.style.opacity = "0";
        scrollLink.style.visibility = "hidden";
      }
    }
  }
};

headerScrollHandler();

window.addEventListener("scroll", () => {
  headerScrollHandler();
});

