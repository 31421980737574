// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./actions"
import "./controllers";
import TomSelect from "tom-select";
import * as Popper from "@popperjs/core";
import * as bootstrap from "bootstrap"
import wowjs from "wowjs/dist/wow.min";
import "mmenu-js/dist/mmenu";
import Choices from "choices.js";
import "trix"
import "@rails/actiontext"
import "./helpers/local_time"
import "./helpers/scroll_handler"

globalThis.bootstrap = bootstrap

const wow = new wowjs.WOW({
  animateClass: "animate__animated",
});

const loadTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  for (const element of tooltipTriggerList) {
    try {
      new bootstrap.Tooltip(element)
    } catch (err) {
      console.log(err)
    }
  }
}

const handlePreloader = () => {
  for (const el of Array.from(document.getElementsByClassName("preloader"))) {
    setTimeout(() => {
      el.style.transition = "opacity 0.5s";
      el.style.opacity = 0;

      setTimeout(() => {
        el.style.display = "none";
      }, 500);
    }, 100);
  }
};

const tomSelectBoxesLoad = (selector = ".input-tom-select", options = { maxOptions: 100 }) => {
  for (const element of document.querySelectorAll(selector)) {
    try {
      new TomSelect(element, options)
    } catch (err) {
      console.log(err)
    }
  }
}

const tomTagingLoad = (selector = ".input-taggable") => {
  for (const element of document.querySelectorAll(selector)) {
    try {
      new TomSelect(element, {
        persist: false,
        createOnBlur: true,
        create: true,
        render:{
          option_create: (data, escapeHtml) => {
            return `<div class="create"><strong>${escapeHtml(data.input)}</strong> ekle&hellip;</div>`;
          },
          no_results: (data, escapeHtml) => {
            return `<div class="no-results">${escapeHtml(data.input)} için sonuç bulunamadı</div> `;
          },
        },
      });
    } catch (err) {
      console.log(err)
    }
  }
}

// const mmenuInit = () => {
//   if (document.getElementById("nav-mobile")) {
//     const navbar = document.getElementById("navbar");
//     const mobileNav = document.getElementById("nav-mobile");

//     const clonedNavbar = navbar.cloneNode(true);
//     clonedNavbar.classList.remove("navbar");
//     mobileNav.appendChild(clonedNavbar);

//     new Mmenu("#nav-mobile", {
//       extensions: ["position-bottom", "fullscreen", "theme-black"],
//       offCanvas: {
//         position: "left",
//         zposition: "front",
//       },
//       theme: "light",
//     });
//   }
// };

const initializeChoices = () => {
  const choicesElements = document.querySelectorAll(".js-choice");

  if (choicesElements.length !== 0) {
    new Choices(".js-choice");
  }
};

const loadPlugins = () => {
  loadTooltips();
  tomSelectBoxesLoad();
  initializeChoices();
  tomTagingLoad();

  // mmenuInit();
}

document.addEventListener("turbo:load", () => {
  loadPlugins();
  handlePreloader();
});

document.addEventListener("turbo:frame-load", loadPlugins);
document.addEventListener("nested_form:add-field", loadPlugins);

document.addEventListener("DOMContentLoaded", () => {
  wow.init();
});
