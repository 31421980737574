import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

const PLACEHOLDER_OPTION = '<option value="">Select...</option>';

// Connects to data-controller="location-select"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    for (const input of this.inputTargets) {
      if (input.dataset.autoTrigger === "true") this.changeLocation({ target: input });
    }
  }

  changeLocation(event) {
    const targetSelector = event.target.dataset.targetInputSelector;

    if (!targetSelector) return;

    const targetSelectBox = document.querySelector(event.target.dataset.targetInputSelector);

    if (!targetSelectBox) return;

    this.#resetDependentSelectBoxes(targetSelectBox);

    if (!event.target.value) return;

    this.#fetchLocations(event.target.value).then(
      locations => {
        targetSelectBox.options.length = 0;
        this.#buildOptions(targetSelectBox, locations);
        targetSelectBox.disabled = false;
      }
    )
  }

  #buildOptions(targetSelectBox, locations) {
    const placeholder = targetSelectBox.getAttribute("placeholder")

    if (placeholder) {
      targetSelectBox.innerHTML = `<option value="">${targetSelectBox.getAttribute("placeholder")}</option>`;
    }

    for (const location of locations) {
      targetSelectBox.add(
        new Option(location.name, location.id)
      );
    }
  }

  #fetchLocations(currentLocationId) {
    return get(`/api/locations/${currentLocationId}/children.json`)
      .then(response => response.json)
  }

  #resetDependentSelectBoxes(selectBox) {
    let current = selectBox;

    while (current) {
      current.disabled = true;

      if (!current.dataset.targetInputSelector) break;

      current = document.querySelector(current.dataset.targetInputSelector);
    }
  }
}
